<template>
    <div  @click="clicked(bloc.id)">

      <b-card class="mb-3">
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            {{ bloc.title }}
            <b-button-toolbar>
              <b-button-group class="mx-1" size="sm">
                <b-button @click="openAdd(bloc, bloc)"><b-icon icon="plus"></b-icon></b-button>
                <b-button @click="openEdit(bloc)"><b-icon icon="pencil"></b-icon></b-button>
                <b-button @click="openDelete(bloc.id)"><b-icon icon="trash"></b-icon></b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
        </template>
          <b-card-body>
              <QuestionItemEdit v-for="question in bloc.questions" :key="question.id" :question="question" :blocId="bloc.id"></QuestionItemEdit>
          </b-card-body>
      </b-card>
      <ModalQuestionEdit :id="`modal-bloc${bloc.id}`" mode="Add"></ModalQuestionEdit>
      
      <b-modal title="Modifier" scrollable :id="`modal-${bloc.id}`" button-size="sm">
        <b-form>
          <label for="">Id : {{ currentBloc.id }}</label>
          <b-form-group id="input-group-1" label="Titre:" label-for="input-1">
            <b-form-input id="input-1" v-model="currentBloc.title" type="text" required></b-form-input>
          </b-form-group> 
        </b-form>
      </b-modal>

      
 
  </div>
  
</template>

<script>
import QuestionItemEdit from "../components/QuestionItemEdit"
import ModalQuestionEdit from './ModalQuestionEdit.vue'

export default {
  name: 'MBlocItemEdit',
  components: {
    QuestionItemEdit,
    ModalQuestionEdit
  },
  props: ['bloc'],
  data: () => ({
    currentBloc : {},
  }),
  mounted() {
  },
  computed : {
    modelVT() {
      return this.$store.getters.modelVT
    },
  },
  methods: {

    clicked(val) {
      this.$emit('clicked', val)
    },
    openAdd(question, parent) {

      console.log("OpenAdd Bloc")
      //
      this.mode="Add"
      const new_question = {
          "id": 0,
          "title": "",
          "comment": "",
          "question_type": "radio",
          "value_type": "radio",
          "default_value": null,
          "list_values": [],
          "value": null,
          "validation_error":null,
          "selected_parent_value": null,
          "sub_questions": null
        }

      this.$store.dispatch('setQuestion', new_question);
      this.$store.dispatch('setParent', parent);
      console.log(`modal-bloc${parent.id}`)
      this.$bvModal.show(`modal-bloc${parent.id}`)

      },
    openEdit(bloc) {
      this.currentBloc = bloc
      this.$bvModal.show(`modal-${bloc.id}`)
    },
    openDelete(id) {
      this.$bvModal.msgBoxConfirm('Voulez vous supprimer cette rubrique ?', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if(value) {
            console.log(id)
            this.modelVT.deleteById(this.modelVT.blocs, id)
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    }
  }
}
</script>

<style>

</style>