import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import modelVT from './modules/modelVT'
import question from './modules/question'
import rules from './modules/rules'
import crm from './modules/crm'
import meeting from './modules/meeting'
import contract from './modules/contract'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    modelVT,
    question,
    rules,
    crm,
    meeting,
    contract
  }
})
