import Api from '@/services/Api'
import authHeader from './Auth-header';

async function EnvoiSignatureElectronique(contratId){

	const token = authHeader()

	return Api.get(`/admin/api/v2/applications/iso/yousign/evidence/admin/SignEvidenceDocument?id=${contratId}&token=${token}`)
}

async function EnvoiSignatureElectroniqueInitiator(contratId){

	const token = authHeader()

	return Api.get(`/admin/api/v2/applications/iso/yousign/evidence/admin/SignEvidenceQuotationInitiator?id=${contratId}&token=${token}`)
}

async function EnvoiSignatureElectroniqueQuotation(quotation){

	const token = authHeader()

	return Api.get(`/admin/api/v2/applications/iso/yousign/evidence/admin/SignEvidenceQuotation?id=${quotation}&token=${token}`)
}

async function ExportPdfSigne(contractId, quotationId, mode){

	let url = mode == 'quotation' ? '/admin/api/v2/applications/iso/yousign/evidence/admin/ListQuotationEvidence' : '/admin/api/v2/applications/iso/yousign/evidence/admin/ListDocumentEvidence'


	let form = new FormData();
	form.append("filter[equal][contract_id]", contractId);

	const token = authHeader()

	const result = await Api.post(`${url}?token=${token}` , form)
	if(result.status != 200 || result.data.error || result.data.errors){

		throw new Error(JSON.stringify(result.data.errors))
	}
	else {

		///api/v2/applications/iso/yousign/evidence/admin/GetFile?contract=98
		if(result.data.data.length > 0) {
			const fileObj = mode == 'quotation' ? result.data.data.filter(x=> x.quotation_id == quotationId) : result.data.data.filter(x=> x.contract_id == contractId)
			if(fileObj) {
				return Api.get(`/admin/api/v2/services/yousign/evidence/admin/SignedFile?id=${fileObj[0].signature.id}&token=${token}` , { responseType: 'blob' })	
			}
			else {
				return Promise.resolve()
			}

		}
		else {
			return Promise.resolve()
		}

	}
}

async function ExportPdfDevisSigne(contractId, quotationId){
	return ExportPdfSigne(contractId, quotationId, 'quotation')
}

async function ExportPdfAHSigne(contractId, quotationId){
	return ExportPdfSigne(contractId, quotationId, 'document')
}

async function ExportProofPdfFile(id){

	const token = authHeader()

	return Api.get(`/admin/api/v2/services/yousign/evidence/admin/ProofPdfFile?id=${id}&token=${token}` , { responseType: 'blob' })
}

export default {
	EnvoiSignatureElectronique, EnvoiSignatureElectroniqueInitiator, ExportPdfDevisSigne, ExportPdfAHSigne, EnvoiSignatureElectroniqueQuotation, ExportProofPdfFile
}
