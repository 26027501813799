<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="question">
        <h5 v-if="question.question_type =='section'">{{question.title}}</h5>
        <SingleFileUpload v-if="question.question_type =='uploadDoc'" :id="question.comment" :label="question.title" :blocId="blocId" :questionId=question.id ></SingleFileUpload>
                  
        <validation-provider
								:name="question.title"
								:rules="question.validation"
								v-slot="validationContext"
				>
          <div v-if="question.question_type == 'inputText'">
              
								<b-form-group :label="question.title" :label-for="question.comment">
									<b-form-input
                    :id="question.comment"
                    :name="question.comment"
                    v-model="question.value" 
                    :state="getValidationState(validationContext)"
                    :type="question.value_type"
                    :aria-describedby="`${question.comment}-feedback`"
									></b-form-input>

									<b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
          </div>
        
          <div v-if="question.question_type == 'radio'">
            <b-form-group :label="question.title" :label-for="question.comment">
              <b-img v-if="question.image" :src="getImgUrl(question.image)" fluid-grow class="mb-3 bordered"></b-img>
              <b-form-radio-group buttons button-variant="outline-primary" 
                :id="question.comment"
                :name="question.comment"
                 v-model="question.value"  
                 :options="question.list_values" 
                 :state="getValidationState(validationContext)" 
                 :aria-describedby="`${question.comment}-feedback`">
              </b-form-radio-group>
              <b-form-invalid-feedback :id="`${question.comment}-feedback`" :state="getValidationState(validationContext)" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </div>  
      
          <div v-if="question.question_type == 'select'">
            <b-form-group :label="question.title" :label-for="question.comment">
              <b-form-select 
                :id="question.comment" 
                :name="question.comment"
                v-model="question.value"  
                :options="question.list_values" 
                :state="getValidationState(validationContext)" 
                :aria-describedby="`${question.comment}-feedback`">
              </b-form-select>
              <b-form-invalid-feedback :id="`${question.comment}-feedback`" :state="getValidationState(validationContext)" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </div>

        <div v-if="question.question_type == 'checklist'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-checkbox-group :id="question.comment" v-model="question.value"  :options="question.list_values" stacked :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-checkbox-group>
            <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>

        </div>

        <div v-if="question.question_type == 'date'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-datepicker :id="question.comment"  v-model="question.value" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-datepicker>
            <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>

        </div>

        <div v-if="question.question_type == 'time'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-timepicker :id="question.comment" v-model="question.value" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-timepicker>
            <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>

        </div>

        <div v-if="question.question_type == 'textarea'">
          <b-form-group :label="question.title" :label-for="question.comment">
            <b-form-textarea :id="question.comment" v-model="question.value"  rows="3" max-rows="6" :state="getValidationState(validationContext)" :aria-describedby="`${question.comment}-feedback`"></b-form-textarea>
            <b-form-invalid-feedback :id="`${question.comment}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>

        </div>
      </validation-provider>
        <div v-if="question.sub_questions && question.sub_questions.length > 0">
          <div v-for="subquestion in question.sub_questions" :key="subquestion.id">
            <b-row v-if="(question.value != null && question.value.length >0) && (subquestion.selected_parent_value == question.value || subquestion.selected_parent_value.includes(question.value))">
              <b-col cols="1"><b-icon icon="arrow-right-square-fill"></b-icon></b-col>
              <b-col cols="11">
                <QuestionItem  :question="subquestion" :blocId="subquestion.id"></QuestionItem>
              </b-col>
            </b-row>
          </div>         
        </div>

  </div>
</template>

<script>
import SingleFileUpload from "../components/SingleFileUpload.vue"


export default {
  name: 'QuestionItem',
  components: {
      SingleFileUpload
  },
  props: ['question','blocId'],
  data: () => ({
    fatalErrorMsg : ""
  }),
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getImgUrl(src) {
      var images = require.context('../assets/', false)
      return images('./' + src)
    }
  }
}
</script>

<style>
.sub_question {
  border: 0px black solid;
  margin-left: 0rem;
}

.invalid-feedback{
  font-size: 125% !important;;
  font-weight: bold;
}

.bordered {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
</style>