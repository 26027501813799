<template>
  <b-container class="pt-2" fluid="sm">

    <h3 class="mt-4">Rendez-vous</h3>

    <form ref="form">
      <b-form-row>
        <b-col>
          <b-form-group
            label="Rechercher un RDV"
            label-for="search"
            invalid-feedback="min 3 caratères"
            required                          
            :state="searchState"
          >
          <b-input-group-append>
            <b-form-input id="search" v-model="keyword" @keyup="searchMeeting()"></b-form-input>
          </b-input-group-append>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-end">
          <b-form-group>
             <b-button @click="openModalMeeting(0)" variant="primary"> Nouveau </b-button>
         
          </b-form-group>
        </b-col>
      </b-form-row>
    </form>

    <b-form >
      <b-form-row>
        <b-col sm="3" md="5">
          <label for="inline-form-input-name">Debut</label>
          <b-form-datepicker id="beginDate" v-model="beginDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                locale="fr" class="mb-2"></b-form-datepicker> 
        </b-col>
        <b-col sm="3" md="5">
          <label for="inline-form-input-username">Fin</label>
          <b-form-datepicker id="beginDate" v-model="endDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          locale="fr" class="mb-2"></b-form-datepicker>
        </b-col >

        <b-col sm="3" md="2" class="d-flex align-items-end" style="margin-bottom:8px">
          <b-button @click="loadMeetings(beginDate,endDate, signed)" variant="primary">Rechercher</b-button>
        </b-col>
      </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-checkbox v-model="signed" name="check-button" switch  @change="loadMeetings(beginDate,endDate, signed)"> Signés
           </b-form-checkbox>
          </b-col>
        </b-form-row>
  </b-form>


    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>

      

    <div>

    <MeetingCard title="Rendez-vous passés" :list="pastAppointments" @modify="openModalMeeting" @new-quotation="routeTo" @update-state="updateState" @update-quotation="routeTo">
      <template v-slot:novalues>Aucun rendez-vous passé</template>
    </MeetingCard>

    <MeetingCalendarCard title="Rendez-vous du jour" :list="todayAppointments" @modify="openModalMeeting" @new-quotation="routeTo">
      <template v-slot:novalues>Aucun rendez-vous aujourd'hui</template>
    </MeetingCalendarCard>

    <MeetingCard title="Rendez-vous à venir" :list="futurAppointments" @modify="openModalMeeting" @new-quotation="routeTo" @update-state="updateState" @update-quotation="routeTo">
      <template v-slot:novalues>Aucun rendez-vous à venir</template>
    </MeetingCard>

    </div>

  </b-skeleton-wrapper>
   <!-- Modale -->
    <b-modal
        id="modal-meeting" ref="modal"
        title="Nouveau meeting"
        okTitle= 'Envoyer'
        cancelTitle= 'Annuler'
        header-bg-variant="primary"
        header-text-variant="white"
        size="lg"
        @show="resetModalMeeting"
        @hidden="resetModalMeeting"
        @ok="handleMeetingOk"
      >
      
      <validation-observer ref="observer" v-slot="{ handleMeetingOk }">
        <!-- Tabs with card integration -->
          <b-form ref="formRDV" @submit.prevent="handleMeetingOk()" @reset="onReset">
            <b-form-row>
              <b-col md="12">
                <b-card title="Bénéficiaire" class="mb-3" >     
                    <b-form-row>
                        <b-col md="12">
                          <b-form-group label="Genre" label-for="gender">
                            <b-form-radio-group button-variant="outline-primary" 
                              id="gender"
                              name="gender"
                              v-model="meeting.customer.gender"  
                              :options="['Mr','Mme']"
                              >
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>            
                      <b-form-row >
                      <b-col md="6">
                        <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="meeting.customer.lastname"></TextInput>
                      </b-col>
                      <b-col md="6">
                        <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="meeting.customer.firstname"></TextInput>
                      </b-col>
                    </b-form-row>
                    <b-form-row >
                      <b-col md="6">
                        <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="meeting.address.address1"></TextInput>             
                      </b-col>           
                      <b-col md="2">
                        <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="meeting.address.postcode"></TextInput>             
                      </b-col>
                      <b-col md="4">
                        <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="meeting.address.city"></TextInput>             
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col md="6">
                        <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="meeting.customer.email"></TextInput>             
                      </b-col>
                      <b-col md="3">
                        <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="meeting.customer.phone"></TextInput>             
                      </b-col>
                      <b-col md="3">
                        <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="meeting.customer.mobile"></TextInput>                 
                      </b-col>
                    </b-form-row>
                    <!-- <b-form-row>            
                      <b-col md="6">
                        <validation-provider
                                name="Energie"
                                :rules="{ required: true}"
                                v-slot="validationContext"
                        >
                        <b-form-group label="Energie" label-for="energy">
                          <b-form-select 
                            id="energy" 
                            v-model="meeting.energy_id"  
                            :options="energyList" 
                            :state="getValidationState(validationContext)" 
                            aria-describedby="energy-feedback">
                          </b-form-select>
                          <b-form-invalid-feedback id="energy-feedback" :state="getValidationState(validationContext)" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>    
                        </validation-provider>                      
                      </b-col>
                      <b-col md="6">
                        <TextInput id="surface" type="number" label="Surface de la maison" :rules="{ required: true, min: 1 , max: 1000}" v-model="meeting.surface_ite"></TextInput>                 
                      </b-col>
                    </b-form-row> -->
                    <!-- <b-form-row>
                      <b-col md="12">
                        <b-form-checkbox v-model="memeAdresse" name="check-button" switch @click="memeAdresse = !memeAdresse">
                          Meme adresse
                        </b-form-checkbox>            
                      </b-col>                     
                    </b-form-row> -->
                </b-card>
              </b-col>             
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <b-card title="Prise de rendez-vous" class="mb-3">
                  <b-form-row>
            <b-col md="6">
               <b-form-group
                    label="Date du rendez-vous"
                    label-for="dateRDV"
                    invalid-feedback="La date de rendez-vous est requise"
                    :state="dateState"
                  >
                  <b-form-datepicker id="dateRDV" placeholder="Choisir une date" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
       locale="fr" v-model="meeting.in_at_date" required :state="dateState"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                    label="Heure"
                    label-for="heureRDV"
                    invalid-feedback="La date de rendez-vous est requise"
                    :state="heureState"
                  >
              <b-form-timepicker id="heureRDV" placeholder="Choisir une heure" v-model="heureRDV" minutes-step="15" locale="fr"></b-form-timepicker>
              </b-form-group>
            </b-col>
          </b-form-row>
                </b-card>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <b-card title="Status" class="mb-3">
                      <validation-provider
                          name="Statut"
                          :rules="{ required: true}"
                          v-slot="validationContext"
                        >
                      <b-form-group label="" label-for="statut">
                          <b-form-select 
                            id="statut" 
                            v-model="meeting.state_id"  
                            :options="stateMeetingList" 
                            :state="getValidationState(validationContext)" 
                            aria-describedby="statut-feedback">
                          </b-form-select>
                          <b-form-invalid-feedback id="statut-feedback" :state="getValidationState(validationContext)" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>   
                      </validation-provider>
                    </b-card>
              </b-col>
            </b-form-row>
          </b-form>
      </validation-observer>     
    </b-modal>
  </b-container>
</template>

<script>
//import EvenementService from '../services/EvenementService'
import MeetingService from '../services/MeetingService'
import TextInput from '../components/TextInput.vue'
import MeetingCard from '../components/MeetingCard.vue';
import MeetingCalendarCard from '../components/MeetingCalendarCard.vue';
const _ = require('lodash');

export default {
  name: 'MMeetings',
  components: {
    TextInput,
    MeetingCard,
    MeetingCalendarCard
  },
  data: () => ({
    beginDate : new Date().toISOString().split('T')[0],
    endDate : new Date().toISOString().split('T')[0],
    signed : false,
    message : '',
    typeMeeting : "Particulier",
    typeMeetingList : ["Particulier","Entreprise"],
    energyList : [],
    stateMeetingList : [],
    memeAdresse : true,
    loading : false,
    meeting : null,
    meetings: [],
    filtreMeetings : [],
    keyword :'',
    dateState : '',
    dateRDV: '',
    dateNaissanceState: '',
    heureRDV: '12:00',
    heureState: '',
    notes:'',
    mpr:'',
    searchState: null,
    articleState : null,
    
  }),
  async mounted(){
    try {    
      await this.loadMeetings( this.beginDate, this.endDate)     
    } catch (error) {
      console.log(error)
    }

    try {
       await this.loadStateMeetingList()     
    } catch (error) {
       console.log(error)      
     }

  },
  computed : {
    todayAppointments() {
      return this.filtreMeetings.filter(x=> {
        const date = new Date(x.in_at)
        const now = new Date()
        return date.toDateString() == now.toDateString()
      })
    }
    ,
    pastAppointments() {
      const past = this.filtreMeetings.filter(x=> {
        const date = new Date(x.in_at)
        const now = new Date()
        return date < now && date.toDateString() != now.toDateString()
      })

      return _.chain(past)
            .groupBy("in_at")
            .toPairs()
            .map(function(currentData){
              return _.zipObject(["in_at", "appointments"], currentData);
            })
            .orderBy(['in_at'])
            .value();
      },
    futurAppointments() {
      const futur = this.filtreMeetings.filter(x=> {
        const date = new Date(x.in_at)
        const now = new Date()
        return date > now && date.toDateString() != now.toDateString()
      })

      return _.chain(futur)
            .groupBy("in_at")
            .toPairs()
            .map(function(currentData){
              return _.zipObject(["in_at", "appointments"], currentData);
            })
            .orderBy(['in_at'])
            .value();
      }
  },
  watch: {
    informations: {
      handler(){
        this.$refs.observer.validate().then(success => {
          //this.showTabs = success

          if(success) {
            //this.$store.dispatch("setMeeting", this.informations)
          }
        })
      },
      deep: true
    },
  },

  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    routeTo(customer_id, meeting_id, mode) {
      console.log(`/devis/meeting/${customer_id}/${meeting_id}`)
      this.$store.dispatch('setMeeting', this.meetings.find(x=>x.id == meeting_id))
      this.$store.dispatch('setContract', null)
      this.$router.push(`/devis/meeting/${customer_id}/${meeting_id}/${mode}`)
    },
    newMeeting () {
      return {
            "id": 0,
            "customer_id": 0,
            "in_at": null,
            "out_at": null,
            "state_id": 63,
            "status": null,
            "sales_id": 0,
            "sale2_id": 0,
            "telepro_id": 0,
            "assistant_id": 0,
            "callback_at": null,
            "is_callback_cancelled": null,
            "callback_cancel_at": null,
            "lock_user_id": null,
            "lock_time": null,
            "is_locked": null,
            "campaign_id": null,
            "callcenter_id": null,
            "type_id": null,
            "status_call_id": null,
            "confirmator_id": null,
            "is_qualified": null,
            "sale_comments": null,
            "status_lead_id": null,
            "confirmed_at": null,
            "treated_at": null,
            "created_by_id": null,
            "confirmed_by_id": null,
            "registration": "",
            "turnover": null,
            "state_updated_at": null,
            "polluter_id": 106,
            "is_hold": null,
            "opc_at": new Date().toLocaleDateString('fr-FR'),
            "is_works_hold": null,
            "opc_range_id": 1,
            "in_at_range_id": null,
            "is_hold_quote": null,
            "partner_layer_id": null,
            "company_id": null,
            "creation_at": null,
            "remarks": "",
            "is_confirmed": null,
            "created_at": null,
            "updated_at": null,
            more_2_years : "YES",
            revenue : 1000,
            energy_id: 1,
            occupation_id : 1,
            layer_type_id : 1,
            surface_home : 0,
            pricing_id : 5,
            number_of_people : 1,
            number_of_parts : 1,
            declarants: 1,
            number_of_fiscal : 1,
            surface_ite : 1,
            parcel_surface : 0,
            pack_quantity :1,
            "customer": {
                "id": 0,
                "gender": "Mr",
                "firstname": null,
                "lastname": null,
                "email": null,
                "phone": null,
                "mobile": null,
                "phone1": null,
                "birthday": null,
                "salary": null,
                "mobile2": null,
                "company": null,
                "union_id": null,
                "age": null,
                "occupation": null,
                "created_at": null,
                "updated_at": null,
                "status": null
            },
            "address": {
                "id": 0,
                "customer_id": 0,
                "address1": null,
                "address2": null,
                "postcode": null,
                "city": null,
                "country": null,
                "state": null,
                "status": null,
                "created_at": null,
                "updated_at": null
            }
        }
    },

    async loadEnergyList() {
      try {
        this.loading= true;
        let result = await MeetingService.ListEnergy()
          this.loading = false;
          if(result.status == 200) {
            this.energyList = Object.entries(result.data).map(entry => {return {value:entry[0], text: entry[1]}})
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger la liste des energies : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },

    async loadStateMeetingList() {
      try {
        this.loading= true;
        let result = await MeetingService.ListStateMeeting()
          this.loading = false;
          if(result.status == 200) {
            this.stateMeetingList = Object.entries(result.data).map(entry => {return {value:entry[0], text: entry[1]}})
            this.$store.dispatch("setStates", this.stateMeetingList)
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger la liste des statuts de meeting : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },

    async loadMeetings(beginDate, endDate, signed){
      try {

        this.loading= true;
        let result = await MeetingService.ListMeeting(beginDate, endDate, signed)
          this.loading = false;
          if(result.status == 200) {
            this.totalRows = result.data.number_of_items
            this.meetings = result.data.data

            this.filtreMeetings = JSON.parse(JSON.stringify(this.meetings))
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger la liste des meetings : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async getMeeting(id){
      
      try {

        let result = await MeetingService.GetMeeting(id)
        if(result.status == 200) {
          this.meeting = result.data.data
          this.meeting.in_at_date = this.meeting.in_at.split(' ')[0]
          this.heureRDV = this.meeting.in_at.split(' ')[1].substring(0,5)
        }
      } catch (error) {
         this.$bvModal.msgBoxOk(`Impossible de charger le RDV : ${error}`, {
           title: 'Erreur',
           okVariant: 'danger',
           headerClass: 'p-2 border-bottom-0',
           footerClass: 'p-2 border-top-0',
           centered: true
         })
      }
  
    },
    searchMeeting(){
      if(this.keyword.length < 1) {
        this.filtreMeetings = JSON.parse(JSON.stringify(this.meetings))
        return
      }

      this.filtreMeetings = this.meetings.filter(
        x=> x.customer.firstname.toUpperCase().includes(this.keyword.toUpperCase() || 
        x.customer.lastname.toUpperCase().includes(this.keyword.toUpperCase()))
        )
    },
    checkFormMeetingValidity() {
        let valid = this.$refs.formRDV.checkValidity()

        if(!this.meeting.in_at_date)
          this.dateState = valid
        else
          this.dateState = true

      
        return valid
    },
    resetModalMeeting() {
      this.dateState = null
    },
    async openModalMeeting(id) {

      if(id != 0) {
        //await this.getMeeting(id)
        this.meeting = {... this.meetings.find(x=>x.id == id)}
        this.meeting.in_at_date = this.meeting.in_at.split(' ')[0]
        this.heureRDV = this.meeting.in_at.split(' ')[1].substring(0,5)
        console.log(this.meeting)
      }else {
        this.meeting = this.newMeeting()
      }
      this.$bvModal.show(`modal-meeting`)
    },
    handleMeetingOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$refs.observer.validate().then(success => {
          if(success) {
            // Trigger submit handler
            this.handleSubmitMeeting()
          }
        })

    },
    async updateState(stateId, id) {
      console.log('update state',stateId, id)
      this.meeting = {... this.meetings.find(x=>x.id == id)}
      this.meeting.state_id = stateId
      await this.handleSubmitMeeting()
    },
    async handleSubmitMeeting() {
      // Exit when the form isn't valid

        let result = null
         try {
          

          this.meeting.in_at_hour = this.heureRDV.split(':')[0]
          this.meeting.in_at_minute = this.heureRDV.split(':')[1]

          console.log(this.meeting)

          if(this.meeting.id == 0) {
            result = await MeetingService.NewMeeting(this.meeting)
          }
          else {
            this.meeting.more_2_years = "YES"
            this.meeting.revenue = 1000
            this.meeting.energy_id = 1
            this.meeting.occupation_id = 1
            this.meeting.layer_type_id = 1
            this.meeting.surface_home = 0
            this.meeting.pricing_id = 5
            this.meeting.number_of_people = 1
            this.meeting.number_of_parts = 1
            this.meeting.declarants= 1
            this.meeting.number_of_fiscal = 1
            this.meeting.surface_ite = 1
            this.meeting.parcel_surface = 0
            this.meeting.pack_quantity = 1
            result = await MeetingService.UpdateMeeting(this.meeting)
          }

          if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }   
          
          this.$bvModal.msgBoxOk('RDV enregistré avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            await this.loadMeetings( this.beginDate, this.endDate) 

        } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-meeting')
        })
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
legend {
  font-size: 16px;
}

</style>


