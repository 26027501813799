export default class Rule {
    constructor(label) {
      this.id = -1
      this.label = label;
      this.rubId = 0;
      this.conditions  = [];
      this.condition = ""
      this.productId  =0;

    }
  }

