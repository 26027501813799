
import IcallService from '@/services/IcallService';

const state =  {
    rules:  []
  };
  
  const getters = {
    rules : state => { return state.rules}
  }
  
  const mutations = {
    SET_RULES(state, rules) {
        state.rules = rules
    },
    FETCH_RULES(state){
      return IcallService.fetchRules()
        .then(result => {
           state.rules = result.data;
        })
        .catch(() => {
          //console.log(err)        
        });
      }
  }
  
  const actions = {
    setRules( context , modelVT) {
      context.commit('SET_RULES', modelVT)
    },
    fetchRules(context) {
      context.commit('FETCH_RULES')
    }
  }

  export default {
    state, getters, mutations, actions
}