<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <h2 class="mt-4">{{title}}</h2>
    <b-card>
      <b-list-group v-if="list.length > 0">
        <b-list-group-item v-for="hour in hoursOfDay" :key="hour" class="d-flex justify-content-between align-items-center">
          <strong>{{ hour }}</strong>

          <div class="d-flex flex-column w-100">
            <div v-for="client in list" :key="client.id">
              <div v-if="client && client.in_at.split(' ')[1].slice(0, 2) == hour.slice(0, 2) " class="ml-3">
                <span class="font-weight-bold">{{ client.in_at | formatDateTime }}</span>
                <ContactCard :contact="client"> 
                  <template v-slot:action>
                    <b-dropdown variant="outline-primary" right text="Actions">
                      <b-dropdown-item @click="$emit('modify', client.id)">Modifier</b-dropdown-item>
                      <b-dropdown-item @click="$emit('new-quotation', client.customer_id, client.id,'new')" >Creer un devis </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </ContactCard>
              </div>
            </div>
        </div>
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
        <slot name="novalues"></slot>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ContactCard from './ContactCard.vue';

export default {
  name: 'MMeetingCalendarCard',
  components: {
    ContactCard
  },
  props: ['title','list'],
  data: () => ({
    hoursOfDay: [
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00'
      ],
  }),
  methods: {

  }
}
</script>

<style>

</style>