import axios from 'axios'


const instance = axios.create({
    baseURL: `https://appadv.ges.crm.oleweb.fr/api`
    //baseURL: `http://localhost:4000/api`
  })

  // instance.interceptors.response.use(response => {
  //   return response;
  // }, error => {
  //   if (error.response.status === 401) {
  //       localStorage.setItem('jwt', null)
  //       document.location = "/"
  //   }
  //   return Promise.reject(error);
  // });

export default() => {
  return instance
}