<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
  <h2 class="mt-4">{{ title }}</h2>
    <b-card>
      <b-list-group v-if="list.length > 0">
        <b-list-group-item v-for="client in list" :key="client.id" class="d-flex flex-column">

          <b-row>
            <b-col cols="12" sm="3">
              <strong>{{ client.in_at }}</strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div  v-for="client in client.appointments" :key="client.id" style="margin-bottom: 15px;">
                <ContactCard :contact="client" :stateList="stateList" @update-state="emit"> 
                  <template v-slot:action>
                    <b-dropdown variant="outline-primary" right text="Actions">
                      <b-dropdown-item @click="$emit('modify', client.id)">Modifier</b-dropdown-item>
                      <b-dropdown-item @click="$emit('new-quotation', client.customer_id, client.id, 'new')" >Creer un devis </b-dropdown-item>
                      <b-dropdown-item @click="$emit('update-quotation', client.customer_id, client.id, 'update')" >Modifier un devis </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </ContactCard>
                </div>
            </b-col>
          </b-row>
          
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
          <slot name="novalues"></slot>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ContactCard from './ContactCard.vue';
export default {
  name: 'MMeetingCard',
  components: {
    ContactCard
  },
  props: ['title','list'],
  data: () => ({
  
  }),
  computed: {
     stateList () {
        return this.$store.getters.states;
    },
  },
  methods: {
    emit(stateId, contactId) {
      this.$emit('update-state',stateId, contactId)
    }
  }
}
</script>

<style>

</style>