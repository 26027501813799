<template>
    <b-container class="pt-2" fluid="sm">
        
        <h3>Modele</h3>
        
        <div class="d-flex flex-row mb-2  justify-content-end">
            <b-button @click="openAdd()" class="d-flex justify-content-end" ><b-icon icon="plus" variant="outline-dark"></b-icon>Nouvelle Rubrique</b-button>
        </div>

        <BlocItemEdit v-for="bloc in blocs" :key="bloc.id" :bloc="bloc" ></BlocItemEdit>

        <b-modal title="Ajouter" scrollable :id="`modal-addbloc`" button-size="sm">
            <b-form>
            <b-form-group id="input-group-1" label="Titre:" label-for="input-1">
                <b-form-input id="input-1" v-model="new_bloc.title" type="text" required></b-form-input>
            </b-form-group> 
            </b-form>

            <template #modal-footer="{cancel}">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="save()">
                    Enregistrer
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Annuler
                </b-button>
            </template>
        </b-modal>
    </b-container>
</template>

<script>

import BlocItemEdit from '../components/BlocItemEdit'

export default {
    name: 'MQuestionModelManager',
    components: {
        BlocItemEdit
    },
    data : () => ({
        blocs : [],
        loading: false,
        message: '',
        new_bloc: {}
        
    }),
    async mounted(){
        this.blocs = this.$store.getters.modelVT.blocs
    },
    computed: {
        modelVT() {
            return this.$store.getters.modelVT
        },
    },
    methods: {
        openAdd() {

            console.log("OpenAdd Bloc")
            //
            this.new_bloc = {
                "id": 0,
                "title": "",
                "questions": []
            }
            this.$bvModal.show(`modal-addbloc`)

        },
        save() {
            this.new_bloc.id = this.modelVT.getNextId()
            this.blocs.push(this.new_bloc)
            this.$bvModal.hide(`modal-addbloc`)

        },
        cancel() {
            console.log("cancel")
        }
    }
};
</script>
