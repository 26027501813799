
const state =  {
    meeting:  null
  };
  
  const getters = {
    meeting : state => { return state.meeting}
  }
  
  const mutations = {
    SET_MEETING(state, meeting) {
        state.meeting = meeting
    }
  }
  
  const actions = {
    setMeeting( context , meeting) {
          context.commit('SET_MEETING', meeting)
    }
  }
  
  export default {
      state, getters, mutations, actions
  }