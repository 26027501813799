import Vue from 'vue'
import Router from 'vue-router'
//import Login from "@/views/Login"
import Meetings from "@/views/Meetings"
import Devis from "@/views/Devis"
import Admin from "@/views/Admin"
import QuestionModelManager from "@/views/QuestionModelManager"
import RuleManager from "@/views/RuleManager"
import Contracts from "@/views/Contracts"
import ContractDetail from "@/views/ContractDetail"


Vue.use(Router)

const router = new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'Login',
    //   component: Login
    // },
    {
      path: '/meetings',
      name: 'Meetings',
      component: Meetings
    },
    {
      path: '/contracts',
      name: 'Contracts',
      component: Contracts
    },
    {
      path: '/contract/:contract_id',
      name: 'ContractDetail',
      component: ContractDetail
    },
    {
      meta : {
        origine: 'meeting'
      },
      path: '/devis/meeting/:customer_id/:meeting_id/:mode',
      name: 'MDevis',
      component: Devis
    },
    {
      meta : {
        origine: 'contract'
      },
      path: '/devis/contract/:customer_id/:contract_id',
      name: 'MDevis',
      component: Devis
    },
    {
      path: '/admin',
      name: 'MAdmin',
      component: Admin,
      children: [
        {
          path: 'questions',
          component: QuestionModelManager,
        },
        {
          path: 'rules',
          component: RuleManager,
        },
      ],
    }
  ]
})


router.beforeEach((to, from, next) => {
  //const publicPages = ['/','/admin'];
  //const authRequired = !publicPages.includes(to.path);
  //const loggedIn = decodeURIComponent(document.cookie)
  window.addEventListener('message', (event) => {
    if (event.origin === 'https://localhost:4000' || event.isTrusted == true) {
      //const receivedJwt = event.data;

      // Verify the JWT
      // Note: You'll need a library like jsonwebtoken for this
      //const decodedJwt = jwt.verify(receivedJwt, 'your_shared_secret');

      // Extract and use the claims
      //console.log('beforeEach',receivedJwt);
      }
    },{ once: true }
  );
  if(to.path =="/")
  {
    next("/admin")
    
  } 
  else 
  // trying to access a restricted page + not logged in
  // redirect to login page
  //if (authRequired && loggedIn == "") {
  //  next('/');
  //} else {
    next();
 // }
});

export default router