export default class Condition {
    constructor() {
      this.key = "";
      this.operator  = "";
      this.value = ""
    }

    toString() {
      return this.key + this.operator + this.value
    }
  }