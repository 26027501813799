<template>
	<div>
		<b-form-row class="mb-2" v-for="(condition, index) in conditions" :key="index">
			<b-col md="5">
				<b-form-select v-model="condition.key" :options="filtreQuestions" value-field="key" text-field="title"  @change="selectValue(condition.key)"></b-form-select>
			</b-col>
			<b-col md="2">
				<b-form-select v-model="condition.operator" :options="operators"></b-form-select>
			</b-col>
			<b-col md="4">
				<b-form-select v-if="selectValue(condition.key).length > 0" v-model="condition.value" :options="selectValue(condition.key)"></b-form-select>
				<b-form-input v-else v-model="condition.value"></b-form-input>
			</b-col>
			<b-col md="1" class="d-flex justify-content-end">
				<b-button variant="danger"><b-icon icon="x" @click="removeCondition(index)"></b-icon></b-button>
			</b-col>
		</b-form-row>
		<b-form-row>
			<b-col class="mb-3">
				<b-button variant="outline-primary" @click="addCondition()"><b-icon icon="plus"></b-icon>Nouvelle condition</b-button>
			</b-col>
		</b-form-row>
	</div>
</template>
  
<script>
export default {
	props:['questions', 'conditions', 'rubId'],
	data() {
		return {
			filtreQuestions: [],
			operators : [{text:"est égale à",value:"=="}, {text:"est différent de",value:"!="}],
			list_values : []
		};
	},
	created() {		
		this.selectQuestions()
		this.conditions.forEach(condition => {
			console.log(condition.key)
          //this.selectValue(condition.key);
        });
	},
	methods: {
        selectQuestions() {
            this.filtreQuestions = this.questions.filter(q=>  q.rubId == this.rubId)
        },
        selectValue(key) {
			const res = this.filtreQuestions.find(q=> q.key == key)
			if(res) {
				return res.list_values
			}
            return []
        },

		addCondition() {
			this.$emit('add-condition');
		},
		removeCondition(index) {
			this.$emit('remove-condition', index);
		},
	},
	watch: {
		rubId(val) {
			this.selectQuestions(val);
		}
  },
};
</script>
  