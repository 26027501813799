
const state =  {
    contract:  null,
    states : null
  };
  
  const getters = {
    contract : state => { return state.contract},
    states : state => { return state.states }
  }
  
  const mutations = {
    SET_CONTRACT(state, contract) {
        state.contract = contract
    },
    SET_STATES(state, states) {
      state.states = states
  },
  }
  
  const actions = {
    setContract( context , contract) {
          context.commit('SET_CONTRACT', contract)
    },
    setStates( context , states) {
      context.commit('SET_STATES', states)
}
  }
  
  export default {
      state, getters, mutations, actions
  }