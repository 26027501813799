import Api from '@/services/Api'
import authHeader from './Auth-header';


async function GetBilling(contractId){

	let form = new FormData();

	const token = authHeader()

	form.append('filter[equal][contract_id]', contractId);
	form.append('filter[equal][is_last]',"YES"); 
	form.append('filter[equal][state_id]', ""); 

	return Api.post(`/admin/api/v2/applications/iso/admin/ListBilling?token=${token}`, form)
}

async function CreateBilling(contractId, quotationId){

	let form = new FormData();

	const token = authHeader()

	form.append('contract', contractId);
	form.append('quotation', quotationId); 
	form.append('options[to_send]', "NO"); 
	form.append('options[has_asset]', "NO");

	return Api.post(`/admin/api/v2/applications/iso/admin/CreateBillingForContract?token=${token}`, form)
}



async function ExportPdfBilling(contractId){

	const r = Api.get(`/admin/api/v2/applications/iso/admin/ExportPdfBilling?token=${authHeader()}&billing=${contractId}`, { responseType: 'blob' })
	return r
}

export default {
	GetBilling, ExportPdfBilling, CreateBilling
}