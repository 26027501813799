import axios from 'axios';


const instance = axios.create({
  baseURL: localStorage.getItem('crm'),
  // Other configurations if needed
});

// Export a function to update the base URL
export const updateBaseURL = (newBaseURL) => {
  instance.defaults.baseURL = newBaseURL;
};

export default instance;