import Api from '@/services/Api'
import authHeader from './Auth-header';


function GetArticles() {
  return Api.get(`/admin/api/v2/applications/iso3/admin/ListMasterProducts?token=${authHeader()}`)
}

export default {
    GetArticles
}