
const state =  {
    question:  {},
    parent : {}
  };
  
  const getters = {
    question : state => { return state.question},
    parent : state => { return state.parent}
  }
  
  const mutations = {
    SET_QUESTION(state, question) {
        state.question = question
    },
    SET_PARENT(state, parent) {
      state.parent = parent
    }
  }
  
  const actions = {
    setQuestion( context , question) {
          context.commit('SET_QUESTION', question)
    },
    setParent( context , parent) {
      context.commit('SET_PARENT', parent)
    }
  }
  
  export default {
      state, getters, mutations, actions
  }