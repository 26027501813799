<template>
  <div id="app">
    <div>
        <b-navbar  toggleable="lg" type="dark" variant="primary">
          <b-navbar-brand href="#">
            <!-- <img src="./assets/logo-blanc.png" class="d-inline-block align-center" style="width:104px" alt="GES" > -->
            
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <!-- <b-nav-item to='/Meetings'>RDV</b-nav-item>
              <b-nav-item to='/Contracts'>Contrats</b-nav-item> -->
              <b-nav-item v-if="isAdmin" to='/Admin'>Admin</b-nav-item>
              <!-- <b-nav-item to="/Questions">Questions</b-nav-item> -->
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown right>
                <template v-slot:button-content>
                  <em>{{ crm }}</em>
                </template>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="#" @click="logout()">Déconnexion</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    <router-view/>
  </div>
</template>

<script>

import IcallService from './services/IcallService';

export default {
  name: 'App',
  data() {
    return {
      isAdmin: true,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user
    },
    crm() {
      return this.$store.state.crm.crm
    }
  },
  async mounted(){
 
    this.$store.dispatch('fetchModelVT').then(() => {})

      try {
          const result= await IcallService.fetchRules()
          this.$store.dispatch('setRules',result.data)
      } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible de charger les regles : ${error.message}`, {
          title: 'Erreur',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
      }
  },
  
  methods : {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    }
  }
}
</script> 

<style>

body {
  background-image: repeating-linear-gradient(to bottom right, #2c6ca414 0%, #3aacd938 100%);
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /* margin-top: 60px; */
  height: 100vh;
}


</style>
