import Api from '@/services/Api'
import authHeader from './Auth-header';

async function NewFolder(id){

	let form = new FormData();

	const token = authHeader()

	form.append('contract', id);
	
	return Api.post(`/admin/api/v2/services/fidealis/admin/NewFolderForContract?token=${token}`, form)
}

async function GetFolder(id){

	const token = authHeader()
	
	return Api.get(`/admin/api/v2/services/fidealis/admin/GetFolderForContract?token=${token}&contract=${id}`)
}

async function ListDocument(id){

	let form = new FormData();

	const token = authHeader()
	form.append('filter[equal][contract_id]', id);
	
	return Api.post(`/admin/api/v2/services/fidealis/admin/ListDocument?token=${token}`, form)
}


async function ExportPdf(id){

	const r = Api.get(`/admin/api/v2/services/fidealis/admin/File?token=${authHeader()}&id=${id}`, { responseType: 'blob' })
	return r
}

export default {
	NewFolder, GetFolder, ExportPdf, ListDocument
}
