<template>
    <b-container class="pt-2">
        <b-row>
            <b-col sm="2">
                <b-list-group>
                    <b-list-group-item to="/admin/questions">Modele</b-list-group-item>
                    <b-list-group-item to="/admin/rules">Regles</b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col> <router-view /></b-col>
        </b-row>
    </b-container>      
</template>
    
<script>

export default {
    name: 'MAdmin',
    components: {
    },
    data : () => ({
    }),
    methods: {
        created(){
 
            this.$store.dispatch('fetchModelVT').then(() => {
            })
        },
    }
};
</script>

<style>
  
</style>