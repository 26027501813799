<template>
  <div>
      <b-form-row>
        <b-col md="12" >
          <b-alert :show="message !=''" variant="danger" class="text-center">{{message}}</b-alert>
        </b-col>
      </b-form-row>
      <b-form-row v-if="!is_new">
        <b-col md="12" class="mt-5">
          <button type="button" @click="download('quotation', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Telecharger</button>
            <button type="button" @click="download('proof', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Preuve</button>
            <button type="button" @click="download('signed', contractId, quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Document signé</button>
        </b-col>
      </b-form-row>
      <b-form-row v-else>
        <b-col md="12" class="mt-5">
           <b-button block variant="primary" v-if="showCreateButton" @click="creerDevis" :disabled="disabled">{{labelButton}} <b-icon v-show="success" icon="check-circle-fill" variant="info" font-scale="1"></b-icon> </b-button>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12" class="mt-5">
          <div  class="d-flex flex-row" v-if="quotation_id != 0">

              <!-- <b-button v-if="facturationId != 0" variant="primary" @click="editerDevis(facturationId)">Editer devis <b-icon v-show="success" icon="check-circle-fill" variant="info" font-scale="1"></b-icon> </b-button> -->
              
              <div>
                <button type="button" @click="visualiserDevis()"  :disabled="loading" class="btn btn-outline-primary mr-2"><b-spinner v-if="loading" small></b-spinner> Visualiser le devis </button>
              </div>
              
            <div>
                <b-button variant="primary" @click="validerDevis()">Signature electronique</b-button>
              </div>

              <div>
                <b-button v-if="url" variant="primary" @click="visualiserDevisSigne()">Visualiser le devis signe</b-button>
              </div>

              

              <b-modal id="modal-confirm" size="xl" title="Validation du devis">
                <pdf :src="pdfStream"></pdf>
              </b-modal>

            </div>
        </b-col>
      </b-form-row>
  </div>
</template>

<script>

import DevisService from '../services/DevisService'
import YousignService from '../services/YousignService';
import pdf from 'vue-pdf'

export default {
  name: 'CreationDevis',
  components: {
    pdf
  },
  props: ["article","origine"],
  data: () => ({
    show : false,
    success : false,
    loading : false,
    message : '',
    cumac: 80000,
    errors:[],
    disabled: false,
    quotation_id: 0,
    pdfStream: null,
    url: null
  }),
  mounted() {
    
  },
  computed: {
    showCreateButton() {
      return true
    },

    labelButton() {
      return  "Creation du devis"
    },
    calculPrimeCEE_TTC() {
        return this.modelVT.facteurCorrectif * 520
    },
    calculPrimeCEE_HT() {
        return this.calculPrimeCEE_TTC / (1+ this.modelVT.tva)
    },
    calculPrimeCEE_TVA() {
        return this.calculPrimeCEE_TTC - this.calculPrimeCEE_HT
    },
    meeting_id() {
      return this.$route.params && this.$route.params.meeting_id || 0
    },
    contract_id() {
      return this.$route.params && this.$route.params.contract_id || 0
    },
    customer_id() {
      return this.$route.params && this.$route.params.meeting_id || 0
    },
    volumeCumac() {
        return this.modelVT.facteurCorrectif * this.cumac
    },
    is_new() {
      //console.log()
      return this.$route.params && this.$route.params.meeting_id && this.$route.params.mode == "new"
    },
    valorisationCumac() {
      return (this.volumeCumac * 7600) / 1000000
    }
  },
  methods: {
     async creerDevis() {

      try {
        this.disabled = true
        let result = null
        this.message = ""
        this.show = false;  

        const data = {
          meeting_id : this.meeting_id,
          polluter_id : 106,
          master_item_id : this.article.id,
          item_id : this.article.id,
          quantity : 1,
          price :  this.article.sale_price
        }

        //console.log(this.$route)
        if(this.$route.meta.origine =="meeting") {
          result = await DevisService.NewDevisMeeting(data)
        }
        else {
          data.contract_id = this.contract_id
          delete data.meeting_id          
          result = await DevisService.NewDevisContract(data)
        }
        
        if(result.status != 200 || result.data.errors) {
          this.$bvModal.msgBoxOk(`Impossible créer le devis : ${JSON.stringify(result.data.errors)}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          this.disabled = false
          return
        }   
          this.success = true
          this.quotation_id = result.data.id
          
          this.$bvModal.msgBoxOk('Devis créé avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })

        } catch (error) {
          this.disabled = false
          this.$bvModal.msgBoxOk(`Impossible de créer le devis : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
      } ,

      async visualiserDevis() {

      try {
        this.message = ""
        this.loading = true
        let result = await DevisService.ExportPdfDevis(this.quotation_id)
        this.loading = false

        this.pdfStream = URL.createObjectURL(new Blob([result.data]));

        if(result.status != 200 || result.data.errors) {
          this.$bvModal.msgBoxOk(`Impossible de visualiser le devis : ${JSON.stringify(result.data.errors)}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          return
        }   

        this.$bvModal.show('modal-confirm')
          
        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Impossible de visualiser le devis : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
      }, 

      async validerDevis() {

        try {
          this.message = ""
          this.loading = true
          let result = await YousignService.EnvoiSignatureElectroniqueQuotation(this.quotation_id)
          this.loading = false

          if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible d'envoyer le devis en signature electronique' : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }   


          } catch (error) {
            this.loading = false
            this.$bvModal.msgBoxOk(`Impossible d'envoyer le devis en signature electronique : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
          }
      },

      async visualiserDevisSigne() {

        try {
          this.message = ""
          this.loading = true
          let result = await DevisService.ExportPdfDevis(this.quotation_id)
          this.loading = false

          this.pdfStream = URL.createObjectURL(new Blob([result.data]));

          if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible de visualiser le devis : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }   

          this.$bvModal.show('modal-confirm')
            
          } catch (error) {
            this.loading = false
            this.$bvModal.msgBoxOk(`Impossible de visualiser le devis : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
          }
      },
      async download(type, id, id2) {

        try {
          this.loading = true
          let result = null
          
          switch (type) {
            case "quotation":
              result = await DevisService.ExportPdfDevis(id) // quotationId
              break;
            case "fidealis":
              //result = await FidealisService.ExportPdf(id) // fileId
              break;
            case "signed":
              result = await DevisService.ExportPdfDevis(id2) // YousignService.ExportPdfDevisSigne(id, id2) // contractId, quotationId
              break;
            default:
              break;
          }

          this.loading = false


          if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible de telecharger le document : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }   

          window.open(URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' })))
          //this.$bvModal.show('modal-confirm')
            
          } catch (error) {
            this.loading = false
            this.$bvModal.msgBoxOk(`Impossible de visualiser le document : ${error.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
        }
        },  
    }
  }
</script>

<style scoped>
p {
  font-size: 0.8em;
}
</style>