<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="flex-column ">
    <!-- <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{contact.customer.lastname}} {{contact.customer.firstname}}</h5>
      <b-badge class="d-flex align-items-center"> {{ contact.state.value }}</b-badge>
    </div> -->
    <b-row>
      <b-col><h5 class="mb-1">{{contact.customer.lastname}} {{contact.customer.firstname}}</h5></b-col>
      <b-col sm="4" lg="3" class="d-flex justify-content-end">
        <b-badge v-if="!edit" class="d-flex align-items-center"> {{ contact.state.value }}  <b-icon icon="pencil" @click="toggleMode"></b-icon></b-badge>
        <div  v-else >
          
          <b-input-group>
            <b-form-select v-model="contact.state_id" :options="stateList" @change="selectState(contact.state_id, contact.id)"></b-form-select>

            <b-input-group-append>
              <b-input-group-text>
                <b-icon icon="x" @click="toggleMode"></b-icon>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </div>
        
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div>
        <p class="mb-1"><b-icon icon="globe"></b-icon> :{{contact.address.address1}}, {{contact.address.postcode}}, {{contact.address.city}} </p>
        <p class="mb-1"><b-icon icon="telephone"></b-icon> : <span>{{contact.customer.phone}}</span></p>
        <p class="mb-1"><b-icon icon="phone"></b-icon> : <span>{{contact.customer.mobile}}</span></p>
        <p class="mb-1"><b-icon icon="envelope"></b-icon> :<span> {{contact.customer.email}}</span></p>
      </div></b-col>
      <b-col class="d-flex align-items-center justify-content-end"><slot name="action"></slot></b-col>
    </b-row>
    
    <div class="d-flex w-100 justify-content-between">
      
      <div class="d-flex align-items-center">
        
      </div>
    </div>  
  </div>
</template>

<script>

export default {
  name: 'MContactCard',
  props: ['contact', 'stateList'],
  components: {
  },
  data: () => ({
    edit : false
  }),
  methods: {
    toggleMode() {
      this.edit = !this.edit;
    },
    selectState(stateId, contactId) {
      this.toggleMode()
      console.log("emit contact card")
      this.$emit('update-state', stateId, contactId)
    }
  }
}
</script>

<style>

</style>