<template>
    <b-modal :title="title" scrollable :id="id" button-size="sm">
    <b-form v-if="currentQuestion">
    <label for="">Id : {{ currentQuestion.id }}</label>

    <b-form-group id="input-group-1" label="Titre:" label-for="input-1">
        <b-form-input id="input-1" v-model="currentQuestion.title" type="text" required></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-2" label="Type de question:" label-for="input-2">
        <b-input-group>
        <template #prepend>
            <b-input-group-text>
            <b-icon :icon="iconType(currentQuestion.question_type)"> </b-icon>
            </b-input-group-text>
        </template>
        <b-form-select v-model="currentQuestion.question_type">
        <b-form-select-option value="radio">Radio boutton</b-form-select-option>
        <b-form-select-option value="select">Selection</b-form-select-option>
        <b-form-select-option value="inputText">Texte</b-form-select-option>
        <b-form-select-option value="checklist">Choix multiple</b-form-select-option>
        <b-form-select-option value="textarea">Zone de texte</b-form-select-option>
        </b-form-select>
    </b-input-group>
    </b-form-group>

    <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }" v-if="currentQuestion.question_type=='inputText'">
        <b-form-checkbox-group
        v-model="currentQuestion.value_type" id="checkboxes-4"
        :aria-describedby="ariaDescribedby"
        >
        <b-form-checkbox value="text">Texte</b-form-checkbox>
        <b-form-checkbox value="numeric">Numerique</b-form-checkbox>
        </b-form-checkbox-group>
    </b-form-group>

    <b-form-group v-if="currentQuestion.question_type=='select' || currentQuestion.question_type=='radio' || currentQuestion.question_type=='checklist'">
        <label for="tags-basic">Valeurs</label>
        <b-form-tags input-id="tags-basic" v-model="currentQuestion.list_values" addButtonText="Ajouter"></b-form-tags>
    </b-form-group>

    <b-form-group id="input-group-3" label="Validation:" label-for="input-3" description="Ex : required|numeric|min_value:0">
        <b-form-input id="input-3" v-model="currentQuestion.validation" type="text">
        </b-form-input>
    </b-form-group>

    <b-form-group id="input-group-6" label="Reference:" label-for="input-6" description="Permet une recherche rapide par reference">
        <b-form-input id="input-6" v-model="currentQuestion.comment" type="text">
        </b-form-input>
    </b-form-group>

    <b-form-group v-if="currentParent" id="input-group-5" label="Afficher la question si repondu a la question parente:" label-for="input-5">
        {{  currentParent.title }}
        <b-form-checkbox-group v-model="currentQuestion.selected_parent_value"  :options="currentParent.list_values" stacked></b-form-checkbox-group>
    </b-form-group>
    
    </b-form>

    <template #modal-footer="{cancel}">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="success" @click="save()">
        Enregistrer
      </b-button>
      <b-button size="sm" variant="danger" @click="cancel()">
        Annuler
      </b-button>
    </template>
</b-modal>

</template>
<script>
 
  
export default {
  name: 'ModalQuestionEdit',
  components: {

  },
  props: {
    id : {
      type: String
    },
    mode : {
      type : String
    }
  },
  data: () => ({
  }),
  computed: {
    currentQuestion () {
        return this.$store.getters.question
    },
    currentParent () {
        return this.$store.getters.parent
    },
    title () {
      return this.mode == "Edit" ? "Modifier" : "Ajouter" 
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getImgUrl(src) {
      var images = require.context('../assets/', false)
      return images('./' + src)
    },
    iconType(questionType) {
      switch(questionType) {
        case 'radio':
          return "ui-radios"
        case 'select':
          return "list"
        case 'inputText':
          return "input-cursor-text"
        case 'checklist':
          return "list-check"
        case 'date':
          return "calendar2-date"
        case 'time':
          return "stopwatch"
        case 'textarea':
          return "textarea-resize"
      }
    },
    save() {
      if(this.mode == "Add") {
        console.log("save Add")
        this.currentQuestion.id = this.modelVT.getNextId()
        if(this.currentParent && "questions" in this.currentParent ) {
          console.log("Question")
          this.currentParent.questions.push(this.currentQuestion)
        } else if("sub_questions" in this.currentParent ) {
          console.log("SubQuestion")
          if(this.currentParent.sub_questions == null) {
            this.currentParent.sub_questions = [this.currentQuestion]
          }
          else {
            this.currentParent.sub_questions.push(this.currentQuestion)
          }
        } 
        this.$bvModal.hide(this.id)
      }
      else {
        console.log("save Edit")
      }
      console.log(this.modelVT.blocs)
      this.$emit('updateModel', this.modelVT.blocs)
    },
    cancel() {
      console.log("cancel")
    }
  }
}
</script>