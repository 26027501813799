import ModelVT from '../../models/modelVT';
import IcallService from '@/services/IcallService';

const state =  {
    modelVT:  new ModelVT()
  };
  
  const getters = {
    modelVT : state => { return state.modelVT}
  }
  
  const mutations = {
    SET_MODELVT(state, modelVT) {
        state.modelVT = modelVT
    },
    FETCH_MODELVT(state){
      return IcallService.fetchModelVT()
        .then(result => {
           state.modelVT.blocs = result.data;
        })
        .catch(() => {
          //console.log(err)        
        });
      }
  }
  
  const actions = {
    setModelVT( context , modelVT) {
      context.commit('SET_MODELVT', modelVT)
    },
    fetchModelVT(context) {
      context.commit('FETCH_MODELVT')
    }
  }
  
  export default {
      state, getters, mutations, actions
  }