import crm from '../../models/crm'

const state =  {
    crm:  crm
  };
  
  const getters = {
    crm : state => { return state.crm}
  }
  
  const mutations = {
    SET_CRM(state, crm) {
        state.crm = crm
    }
  }
  
  const actions = {
    setCRM( context , crm) {
          context.commit('SET_CRM', crm)
    }
  }
  
  export default {
      state, getters, mutations, actions
  }