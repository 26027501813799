<template>
  <b-tab :title="bloc.title" :title-link-class="linkClass(bloc.id)" @click="clicked(bloc.id)">
    <QuestionItem v-for="question in bloc.questions" :key="question.id" :question="question" :blocId="bloc.id"></QuestionItem>
  </b-tab>
</template>

<script>
import QuestionItem from "../components/QuestionItem"

export default {
name: 'BlocItem',
components: {
    QuestionItem
},
props: ['bloc','selectedTab'],
data: () => ({

}),
mounted() {
  
},
methods: {
  linkClass(tab) {
      if (this.selectedTab === tab) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-primary']
      }
    },
  clicked(val) {
    this.$emit('clicked', val)
  }
}
}
</script>

<style>

</style>